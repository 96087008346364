import { BaseHttpService } from '@/services/base-http-service'
import { FunboxLocation } from '@/types'

class UserService extends BaseHttpService {
    public async getFunboxLocation(id: number): Promise<FunboxLocation> {
        const uri = `/locations/${id}`
        const response = await this.doApiGet<FunboxLocation>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async getFunboxLocations(): Promise<FunboxLocation[]> {
        const uri = '/locations'
        const response = await this.doApiGet<FunboxLocation[]>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async createFunboxLocation(funboxLocation: FunboxLocation): Promise<FunboxLocation> {
        const uri = '/locations'
        const response = await this.doApiPost<FunboxLocation>(uri, JSON.stringify(funboxLocation))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async updateFunboxLocation(id: number, funboxLocation: FunboxLocation): Promise<FunboxLocation> {
        const uri = `/locations/${id}`
        const response = await this.doApiPut<FunboxLocation>(uri, JSON.stringify(funboxLocation))
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }

    public async deleteFunboxLocation(id: number): Promise<boolean> {
        const uri = `/locations/${id}`
        const response = await this.doApiDelete<boolean>(uri)
        if (response.success) {
            return response.response!
        }
        throw new Error(response.error!.message)
    }
}

export default new UserService()
