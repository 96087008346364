import FunboxLocationService from '@/services/funbox-location-service'
import { FunboxLocation, FunboxLocationFormState, Countries } from '@/types'
import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'

interface FunboxLocationState {
    loading: boolean
    funboxLocation: FunboxLocation
    funboxLocationFormState: FunboxLocationFormState
    dataChanged: boolean
    funboxLocations: FunboxLocation[]
    errors: any
}

const toast = useToast()

export const useFunboxLocationStore = defineStore('funboxLocation', {
    state: (): FunboxLocationState => {
        return {
            loading: false,
            funboxLocation: {
                id: -1,
                createdAt: new Date(),
                updatedAt: new Date(),
                name: '',
                address1: '',
                address2: '',
                city: '',
                governingDistrict: '',
                country: '',
                postalCode: '',
                latitude: '',
                longitude: '',
                sacoaIpAddress: '',
                sacoaPort: 443,
                sacoaComputerId: '',
                sacoaUsername: '',
                sacoaPassword: '',
            },
            dataChanged: false,
            funboxLocationFormState: FunboxLocationFormState.None,
            funboxLocations: [],
            errors: {},
        }
    },

    actions: {
        async getLocation (id: number) {
            try {
                this.errors = {}
                const location = await FunboxLocationService.getFunboxLocation(id)
                this.setLocation(location)
                this.setFunboxLocationFormState(FunboxLocationFormState.Edit)
            } catch (err: any) {
                toast.error(`${err}`)
            }
        },

        async getLocations () {
            try {
                this.loading = true
                this.funboxLocations = await FunboxLocationService.getFunboxLocations()
            } catch (err: any) {
                toast.error(`${err}`)
            } finally {
                this.loading = false
            }
        },

        async addUpdateLocation () {
            let successMessage
            let errorMessage

            try {
                if (this.funboxLocationFormState === FunboxLocationFormState.Add) {
                    successMessage = 'created'
                    errorMessage = 'creating'
                    this.funboxLocation = await FunboxLocationService.createFunboxLocation(this.funboxLocation)
                    this.setFunboxLocationFormState(FunboxLocationFormState.Edit)
                } else {
                    successMessage = 'updated'
                    errorMessage = 'updating'
                    this.funboxLocation = await FunboxLocationService.updateFunboxLocation(this.funboxLocation.id, this.funboxLocation)
                }

                toast.success(`location ${successMessage}`)
                this.setDataChanged(false)
                await this.getLocations()
            } catch (err: any) {
                toast.error(`${err}`)
            }
        },

        async deleteLocation () {
            try {
                await FunboxLocationService.deleteFunboxLocation(this.funboxLocation.id)
                toast.success('location deleted')
                this.setLocation()
                this.setFunboxLocationFormState(FunboxLocationFormState.None)
                await this.getLocations()
            } catch (err: any) {
                toast.error(`${err}`)
            }
        },

        setLocation (location: FunboxLocation|null = null) {
            if (location) {
                this.funboxLocation = location
            } else {
                this.funboxLocation = {
                    id: -1,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    name: '',
                    address1: '',
                    address2: '',
                    city: '',
                    governingDistrict: '',
                    country: '',
                    postalCode: '',
                    latitude: '',
                    longitude: '',
                    sacoaIpAddress: '',
                    sacoaPort: 443,
                    sacoaComputerId: '',
                    sacoaUsername: '',
                    sacoaPassword: '',
                }
            }
        },

        setFunboxLocationFormState (state: FunboxLocationFormState) {
            this.funboxLocationFormState = state
        },

        setDataChanged (dataChanged: boolean) {
            this.dataChanged = dataChanged
        },

        validate () {
            if (!this.funboxLocation.name) {
                this.errors['name'] = true
            } else {
                delete this.errors['name']
            }
            if (this.funboxLocation.postalCode && this.funboxLocation.postalCode.length > 15) {
                this.errors['postalCode'] = true
            } else {
                delete this.errors['postalCode']
            }
            if (this.funboxLocation.country && !Object.keys(Countries).find(k => k === this.funboxLocation.country)) {
                this.errors['country'] = true
            } else {
                delete this.errors['country']
            }
            if (this.funboxLocation.latitude &&
                (isNaN(parseFloat(this.funboxLocation.latitude)) ||
                    parseFloat(this.funboxLocation.latitude) < -90 || parseFloat(this.funboxLocation.latitude) > 90)) {
                this.errors['latitude'] = true
            } else {
                delete this.errors['latitude']
            }
            if (this.funboxLocation.longitude &&
                (isNaN(parseFloat(this.funboxLocation.longitude)) ||
                    parseFloat(this.funboxLocation.longitude) < -180 || parseFloat(this.funboxLocation.longitude) > 180)) {
                this.errors['longitude'] = true
            } else {
                delete this.errors['longitude']
            }
            if (!this.funboxLocation.sacoaIpAddress) {
                this.errors['sacoaIpAddress'] = true
            } else {
                delete this.errors['sacoaIpAddress']
            }
            if (!this.funboxLocation.sacoaPort || this.funboxLocation.sacoaPort < 80 || this.funboxLocation.sacoaPort > 65535) {
                this.errors['sacoaPort'] = true
            } else {
                delete this.errors['sacoaPort']
            }
            if (!this.funboxLocation.sacoaComputerId) {
                this.errors['sacoaComputerId'] = true
            } else {
                delete this.errors['sacoaComputerId']
            }
            if (!this.funboxLocation.sacoaUsername) {
                this.errors['sacoaUsername'] = true
            } else {
                delete this.errors['sacoaUsername']
            }
            if (this.funboxLocationFormState === FunboxLocationFormState.Add && !this.funboxLocation.sacoaPassword) {
                this.errors['sacoaPassword'] = true
            } else {
                delete this.errors['sacoaPassword']
            }
        },
    }
})
